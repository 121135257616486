<template>
  <div class="card">
    <h3><slot name="header" :firstName="f" :lastName="l"></slot></h3>
    <slot>Default Content</slot>
    <h6><slot name="footer"></slot></h6>
  </div>
</template>

<script>
export default {
  name: "Card",
  data() {
    return { f: "Bruce", l: "Wayne" };
  },
};
</script>

<style scoped>
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 200px;
  padding: 16px;
  margin-bottom: 20px;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
</style>