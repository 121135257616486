<template>
  <div>Tab C Content</div>
  <input type="text">
</template>

<script>
export default {
  name: "TabC",
};
</script>

<style scoped>
</style>