<template>
  <h2 v-bind="$attrs" @click="upCount">
    Hello {{ addedUserName }} aka {{ modelValue }} ({{ counter }})
  </h2>
  <h3>{{ addedName }}</h3>
  emit this:<input
    type="text"
    :value="modelValue"
    @input="sendEvent"
    ref="inputRef"
  />
</template>

<script>
import {
  reactive,
  toRefs,
  computed,
  watch,
  provide,
  inject,
  onMounted,
  ref,
} from "vue";
export default {
  name: "Greet",

  setup(props, context) {
    console.log("props", props);

    const inputRef = ref(); // could be in state instead
    const state = reactive({
      c_name: "batman",
      counter: 0,
    });

    function sendEvent(event) {
      context.emit("update:modelValue",event.target.value)
    }

    provide("theName", "theValue"); // make available to other components

    const addedName = inject("theName", "defaultValue"); // brings in value like above but from another component
    const addedUserName = inject("userName", "no name given");

    const compName = computed(() => {
      return `${state.c_name} (${state.counter})`;
    });

    watch(
      () => state.counter,
      (newValue, oldValue) => {
        if (newValue != oldValue) console.log(newValue, oldValue);
      },
      { immediate: true }
    );

    onMounted(() => {
      console.log("mounted");
      //this.$refs.inputRef.focus();
      inputRef.value.focus();
    });

    return {
      sendEvent,
      inputRef,
      addedName,
      addedUserName,
      compName,
      upCount: () => {
        state.counter++;
      },
      ...toRefs(state),
    };
  },

  props: {
    modelValue: String,
    name: { type: String, required: true },
    count: { type: Number, default: 0 },
  },
};
</script>

<style scoped>
</style>