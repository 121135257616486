<template>
  <div>Tab A Content</div>
</template>

<script>
export default {
  name: "TabA",
};
</script>

<style scoped>
</style>