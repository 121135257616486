<template>
  <div>
    <hover-counter></hover-counter>
    <click-counter></click-counter>

    <post-list></post-list>
    <create-post></create-post>

    <button @click="activeTab = 'TabA'">Tab A</button>
    <button @click="activeTab = 'TabB'">Tab B</button>
    <button @click="activeTab = 'TabC'">Tab C</button>
    <keep-alive>
      <component :is="activeTab"></component>
    </keep-alive>

    <teleport to="#modal-root"> Modal popup could go here </teleport>

    <Card>
      <template v-slot:header="slotProps">
        {{ slotProps.lastName }}, {{ slotProps.firstName }}
      </template>
    </Card>
    <Card>
      <template v-slot:header="slotProps">{{ slotProps.lastName }}</template>
    </Card>
    <Card>
      <template v-slot:header="slotProps">{{ slotProps.firstName }}</template>
      <template v-slot:default>default stuff<i>cool</i></template>
      <template v-slot:footer>footing</template>
    </Card>

    <Greet name="Batman" :count="2" extra="asdf" v-model="firstName"></Greet>
    <div>hello {{ fullName }}</div>
    <button @click="showPopup = true">Show Popup</button>
    <Popup v-show="showPopup" @close="closePopup" />
    <div v-html="htmtest"></div>
    <button :disabled="isDisabled" @click="changeName">Change Name</button>
    <div :class="[isDisabled ? 'r' : 'g', 'b']">{{ add(2, 3, 4) }}</div>
    <div :class="{ r: isDisabled, g: !isDisabled, b: true }">test</div>
    <div>
      <button @click="increment">Up</button>
      {{ count }}
      <button @click="decrement">Down</button>
    </div>
    <pre>{{ JSON.stringify(formValues, null, 2) }}</pre>
    <form @submit.prevent="submitForm">
      <div>
        <label for="name">Name</label>
        <input type="text" id="name" v-model.trim.lazy="formValues.name" />
      </div>
      <div>
        <label for="remoteWork">Remote Work</label
        ><input
          type="checkbox"
          id="remoteWork"
          v-model="formValues.remoteWork"
          true-value="yes"
          false-value="no"
        />
      </div>
      <div><button>Submit</button></div>
    </form>

    <ul v-for="person in group" :key="person.guid">
      <b>{{ person.name }} ({{ person.age }})</b>
      <template v-for="friend in person.friends" :key="friend.id">
        {{ friend.name }},
      </template>
    </ul>
  </div>
</template>

<script>
import Card from "./components/Card.vue";
import ClickCounter from "./components/ClickCounter.vue";
import CreatePost from "./components/CreatePost.vue";
import Greet from "./components/Greet.vue";
import HoverCounter from './components/HoverCounter.vue';
import Popup from "./components/Popup.vue";
import PostList from "./components/PostList.vue";
import TabA from "./components/TabA.vue";
import TabB from "./components/TabB.vue";
import TabC from "./components/TabC.vue";

export default {
  components: {
    Greet,
    Popup,
    Card,
    TabA,
    TabB,
    TabC,
    PostList,
    CreatePost,
    ClickCounter,
    HoverCounter,
  },
  name: "App",
  data() {
    return {
      activeTab: "TabA",
      showPopup: false,
      firstName: "Steven",
      lastName: "Dunn",
      count: 0,
      htmtest: "<b>bold</b>",
      isDisabled: false,
      formValues: {
        name: "t",
        remoteWork: "no",
      },
      group: [
        {
          guid: "0cb26b3e-bf02-4a74-8cfa-a10075eca11e",
          isActive: true,
          age: 39,
          name: "Noelle Watts",
          about:
            "Anim aliquip nisi est esse excepteur tempor ut minim cillum aliquip. Et sint enim laborum ex est. In eiusmod proident id reprehenderit do pariatur consectetur commodo. Irure nisi labore occaecat ad minim eu.\r\n",
          friends: [
            {
              id: 0,
              name: "Bonnie Davidson",
            },
            {
              id: 1,
              name: "Flowers Reed",
            },
            {
              id: 2,
              name: "Albert Aguirre",
            },
          ],
        },
        {
          guid: "cf08fc35-f3c7-4da8-8560-344e7f901ef9",
          isActive: false,
          age: 29,
          name: "Delia Barrera",
          about:
            "Ullamco est sint consequat occaecat culpa laboris excepteur enim nostrud nisi. Aute consequat irure magna eu qui in et et officia laborum proident duis velit reprehenderit. Anim eu aliqua magna labore excepteur dolore cupidatat sit magna veniam voluptate aliquip in anim. Occaecat deserunt laboris deserunt ipsum exercitation duis labore consectetur dolor sint nulla cillum. Culpa laboris culpa elit ullamco proident commodo exercitation.\r\n",
          friends: [
            {
              id: 0,
              name: "Dejesus Mullen",
            },
            {
              id: 1,
              name: "Peterson Puckett",
            },
            {
              id: 2,
              name: "Cecilia Guzman",
            },
          ],
        },
        {
          guid: "72b51a67-7d7e-4825-b255-c764a1f43842",
          isActive: false,
          age: 26,
          name: "Gutierrez Rodriquez",
          about:
            "Ex mollit ut ullamco cupidatat ipsum ea dolore ut dolore consequat id. Aliquip sunt veniam aute dolor veniam quis nulla duis reprehenderit irure est Lorem dolor proident. Elit veniam anim velit nisi incididunt duis id exercitation in do non. Non incididunt duis ad dolore mollit deserunt ex labore dolor. Ipsum esse laborum excepteur non deserunt Lorem occaecat et dolor. Dolore ex sit ipsum pariatur enim nulla esse cillum aliquip ex nisi pariatur pariatur. Officia dolor incididunt in commodo adipisicing velit.\r\n",
          friends: [
            {
              id: 0,
              name: "Maribel Fischer",
            },
            {
              id: 1,
              name: "Cathy Pearson",
            },
            {
              id: 2,
              name: "Bertha Duran",
            },
          ],
        },
        {
          guid: "4a1d6753-5823-4211-9d3b-4f8289a6778b",
          isActive: true,
          age: 36,
          name: "Megan Holloway",
          about:
            "Et duis aute ut non aliqua laboris deserunt proident ullamco. Laboris Lorem occaecat laboris duis qui ipsum nisi labore qui dolor incididunt. Adipisicing excepteur irure minim fugiat sint id nisi tempor est. In fugiat reprehenderit consectetur consequat in fugiat elit exercitation commodo esse.\r\n",
          friends: [
            {
              id: 0,
              name: "Jessie Harmon",
            },
            {
              id: 1,
              name: "Leon English",
            },
            {
              id: 2,
              name: "Gould Reeves",
            },
          ],
        },
        {
          guid: "7a59751b-2256-4cb8-97d3-b1014a4e9207",
          isActive: false,
          age: 26,
          name: "Candy Griffith",
          about:
            "Occaecat velit eu enim ad laborum eiusmod minim nostrud excepteur veniam adipisicing. Commodo occaecat commodo consequat excepteur ex enim dolor irure nostrud ex. Deserunt reprehenderit sit quis sint consequat non magna sunt in. Dolore excepteur enim laborum eu pariatur magna. Est quis Lorem cillum eu adipisicing. Commodo ad incididunt officia irure eu anim nulla esse et culpa.\r\n",
          friends: [
            {
              id: 0,
              name: "Beck Hansen",
            },
            {
              id: 1,
              name: "Genevieve Schneider",
            },
            {
              id: 2,
              name: "Rosalyn Tate",
            },
          ],
        },
        {
          guid: "f0121010-0e39-497e-9baf-1b485c4b757c",
          isActive: true,
          age: 27,
          name: "Shana Tyler",
          about:
            "Aliquip consectetur commodo in cupidatat id sit nostrud deserunt irure esse excepteur occaecat. Officia officia do et ea sunt dolor ad reprehenderit excepteur irure nisi Lorem proident. Tempor velit excepteur enim elit. Ut laborum do enim dolor commodo quis laboris nostrud.\r\n",
          friends: [
            {
              id: 0,
              name: "Odonnell Briggs",
            },
            {
              id: 1,
              name: "Sears Steele",
            },
            {
              id: 2,
              name: "Jerri Roy",
            },
          ],
        },
      ],
    };
  },
  provide() { // passes to all child components
    return { userName: this.fullName };
  },
  methods: {
    closePopup(data) {
      this.showPopup = false;
      console.log(data);
    },
    add(a, b, c) {
      return this.count + a + b + c;
    },
    changeName() {
      this.fullName = "Bat man";
      console.log("name:", this.firstName, this.lastName);
    },
    increment() {
      this.count++;
    },
    decrement() {
      this.count--;
    },
    submitForm() {
      console.log("submit:", this.formValues);
    },
  },
  computed: {
    fullName: {
      get() {
        return `${this.firstName} ${this.lastName}`;
      },
      set(value) {
        const names = value.split(" ");
        this.firstName = names[0];
        this.lastName = names[1];
      },
    },
    activeNames() {
      return this.group.filter((person) => person.isActive);
    },
  },
  watch: {
    count(newValue, oldValue) {
      if (newValue > oldValue && newValue === 16) alert("this is 16");
    },
    firstName: {
      handler(newValue) {
        console.log("name: ", newValue);
      },
      immediate: true, // process on first page load
      deep: true, // watch properties that are top.sub.sub levels
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  margin-top: 60px;
}
.r {
  color: red;
}
.g {
  color: green;
}
.b {
  font-weight: bold;
}
ul {
  text-align: left;
}
</style>
