export default {
    data() {
        return {
            count: 0,
        };
    },
    methods: {
        incrementCount() {
            this.count++;
        },
    },

};